<template>
  <a-layout class='index animated fadeIn'>
    <a-card class="mb10">
      <a-button size='small' @click="()=>{$router.go(-1)}">
        <LeftOutlined />返回
      </a-button>
      <span class="p-l-10 fz-20">{{$route.query.company}}</span>
    </a-card>
    <a-card>
      <a-form :label-col="{span:3}" ref="ruleForm" :model="formData" :rules="rules">
        <a-form-item label="允许外部码参与活动">
          <a-radio-group v-model:value="formData.external_code_is_act">
            <a-radio value="1">是</a-radio>
            <a-radio value="0">否</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="是否有外部码">
          <a-radio-group v-model:value="formData.is_have_external">
            <a-radio value="1">是</a-radio>
            <a-radio value="0">否</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="外部码查询模式" v-if="formData.is_have_external==1">
          <a-radio-group v-model:value="formData.is_external_query">
            <a-radio value="1">外部</a-radio>
            <a-radio value="0">码库</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="查询批次" v-if="formData.is_have_external==1&&formData.is_external_query==0">
          <a-input v-model:value="formData.external_code_batch_sn" style="width:200px"></a-input>
        </a-form-item>
        <a-form-item label="允许工厂写入外部码" v-if="formData.is_have_external==1&&formData.is_external_query==0">
          <a-radio-group v-model:value="formData.is_enable_factory_write">
            <a-radio value="1">是</a-radio>
            <a-radio value="0">否</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="外部查询链接" v-if="formData.is_external_query==1">
          <a-input v-model:value="formData.external_query_config_url" style="width:200px"></a-input>
        </a-form-item>
        <a-form-item label="外部查询请求方式" v-if="formData.is_external_query==1">
          <a-radio-group v-model:value="formData.external_query_config_method">
            <a-radio value="get">get</a-radio>
            <a-radio value="post">post</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="外部查询请求参数" v-if="formData.is_external_query==1">
          <!-- 数组 -->
          <a-input v-model:value="formData.external_query_config_params" placeholder="此数据用于post 提交" style="width:200px"></a-input>
        </a-form-item>
        <a-form-item label="外部查询码字段" v-if="formData.is_external_query==1">
          <a-input v-model:value="formData.external_query_config_code_field" style="width:200px"></a-input>
        </a-form-item>

        <a-form-item label="外部查询请求参数位置" v-if="formData.is_external_query==1">
          <a-select v-model:value="formData.external_query_config_params_position" style="width:200px">
            <a-select-option value="query">query</a-select-option>
            <a-select-option value="form-data">form-data</a-select-option>
            <a-select-option value="json">json</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="码字段位数" v-if="formData.is_external_query==1">
          <a-select v-model:value="formData.external_query_config_code_field_position" style="width:200px">
            <a-select-option value="query">query</a-select-option>
            <a-select-option value="form-data">form-data</a-select-option>
            <a-select-option value="json">json</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="允许PDA录入特殊商品出库单">
          <a-radio-group v-model:value="formData.open_special_code">
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </a-form-item>
        <!-- <a-form-item label="条码长度" v-if="formData.open_special_code==1" required>
          <a-input-number v-model:value="formData.open_special_code_length" :precision="0" style="width:200px"></a-input-number>
          <a-tooltip>
            <template #title>
              <p>仅支持扫码输入纯数字格式条码，当条码格式或长度不符合条件时禁止扫描出库</p>
            </template>
            <QuestionCircleOutlined class="m-l-15" />
          </a-tooltip>
        </a-form-item> -->
        <a-form-item label="外部码格式" v-if="formData.open_special_code==1">
          <a-radio-group v-model:value="formData.open_special_code_type">
            <a-radio :value="1">纯数字</a-radio>
            <a-radio :value="2">防伪码</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="外部码长度" v-if="formData.open_special_code_type==1 &&formData.open_special_code==1" required ref="open_special_code_length" name="open_special_code_length">
          <a-input-number v-model:value="formData.open_special_code_length" :precision="0" style="width:200px"></a-input-number>
        </a-form-item>
        <a-form-item label="防伪码前缀" v-if="formData.open_special_code_type==2&&formData.open_special_code==1" required ref="open_special_code_cut_begin" name="open_special_code_cut_begin">
          <a-input v-model:value="formData.open_special_code_cut_begin" style="width:200px"></a-input>
        </a-form-item>
        <a-form-item label="防伪码长度" v-if="formData.open_special_code_type==2&&formData.open_special_code==1" required ref="open_special_code_cut_length_two" name="open_special_code_cut_length_two">
          <a-input-number v-model:value="formData.open_special_code_cut_length" :precision="0" style="width:200px" :min='0'></a-input-number>
        </a-form-item>
        <a-form-item :wrapper-col="{offset:3}">
          <a-button type="primary" @click="onSubmit">保存</a-button>
        </a-form-item>
      </a-form>

    </a-card>
  </a-layout>
</template>


<script>
import { external_code, external_code_update } from "@/api/codeManage";
import { inject, onMounted, ref, toRefs, reactive } from "@vue/runtime-core";
import { LeftOutlined,
//  QuestionCircleOutlined 
 } from "@ant-design/icons-vue";
import { $iscode } from "../../../utils/app";
import { message } from "ant-design-vue";
export default {
  components: { LeftOutlined, 
  // QuestionCircleOutlined 
  },
  name: "codePrefixDetail",
  props: {
    brandData: Object,
  },
  setup(props) {
        let keywords = async () => {
        if (formData.value.open_special_code_type==2&&!formData.value.open_special_code_cut_length) {
          return Promise.reject("请输入防伪码长度");
        } else {
          return Promise.resolve();
        }
      };
    const ruleForm = ref();
    const state = reactive({
      rules: {
        open_special_code_length: [
          { required: true, message: "请输入外部码长度" },
        ],
        open_special_code_cut_begin: [
          { required: true, message: "请输入截取链接" },
        ],
        open_special_code_cut_length_two: [
            { required: true, validator: keywords}
          ],
      },
    });
    const formData = ref({
      is_have_external: 0,
      is_enable_factory_write: 0,
      is_external_query: 0,
      external_code_batch_sn: "",
      external_code_is_act: 0,
      external_query_config_url: "",
      external_query_config_method: "",
      external_query_config_params: [],
      external_query_config_code_field: "",
      external_query_config_code_field_position: "",
      external_stock: 0,
      open_special_code: 0,
      open_special_code_length: "",
      open_special_code_type: 1,
      open_special_code_cut_begin: "",
      open_special_code_cut_length: "",
    });
    const paramsValue = ref([
      {
        value: "",
      },
    ]);
    const $route = inject("$route");
    const $router = inject("$router");
    const initData = async () => {
      let result = await external_code($route.query.brand_id)
        .then((res) => res.data)
        .catch((error) => error);
      console.log(result);
      formData.value = result.data;
    };
    const onSubmit = async () => {
      ruleForm.value
        .validate()
        .then(async () => {
          // if (
          //   formData.value.open_special_code == 1 &&
          //   !formData.value.open_special_code_length
          // ) {
          //   message.error("请输入条码长度！");
          //   return;
          // }
          const hide = message.loading("正在加载中...", 0);
          let result = await external_code_update($route.query.brand_id, {
            ...formData.value,
          })
            .then((res) => res.data)
            .catch((error) => error);
          setTimeout(hide);
          if ($iscode(result, true)) {
            $router.go(-1);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    onMounted(() => {
      initData();
    });
    const createParam = () => {
      paramsValue.value.push({ value: "" });
    };

    return {
      formData,
      onSubmit,
      createParam,
      paramsValue,
      ruleForm,
      state,
      ...toRefs(state),
    };
  },
};
</script>