
import axios from '@/utils/request'
// 获取列表
export const getCodeList = (params) => {
    return axios.request({
        url: 'code/index',
        method: 'get',
        params
    })
};
// 同步单条生码单 的码
export const sync_code_info = (params) => {
    return axios.request({
        url: 'code/sync_code_info',
        method: 'get',
        params
    })
};
// 同步所有生码单 的码
export const sync_batch_sn = (params) => {
    return axios.request({
        url: 'code//sync_batch_sn',
        method: 'get',
        params
    })
};


// 获取位数详情
export const code_rule_prefix = (brand_id,params) => {
    return axios.request({
        url: 'code_rule/digit/show/'+brand_id,
        method: 'get',
        params
    })
};
// 更新位数
export const updateDigit = (brand_id,data) => {
    return axios.request({
        url: 'code_rule/digit/update/'+brand_id,
        method: 'POST',
        data
    })
};

// 获取前缀详情
export const prefixDetail = (brand_id,params) => {
    return axios.request({
        url: 'code_rule/prefix/show/'+brand_id,
        method: 'get',
        params
    })
};

// 更新前缀
export const updatePrefix = (brand_id,data) => {
    return axios.request({
        url: 'code_rule/prefix/update/'+brand_id,
        method: 'POST',
        data
    })
};


//设置品牌商 PDA虚拟垛标
export const set_pad_virtual = (brand_id,data) => {
    return axios.request({
        url: 'code_rule/set_pad_virtual/'+brand_id,
        method: 'POST',
        data
    })
};


// 获取外部码配置
export const external_code = (brand_id,params) => {
    return axios.request({
        url: 'code_rule/external_code/show/'+brand_id,
        method: 'get',
        params
    })
};

// 获取外部码修改
export const external_code_update = (brand_id,data) => {
    return axios.request({
        url: 'code_rule/external_code/update/'+brand_id,
        method: 'post',
        data
    })
};
// 获取配置
export const getBash = (brand_id,params) => {
    return axios.request({
        url: 'code_rule/bash/show/'+brand_id,
        method: 'get',
        params
    })
};
export const getBashpro = (brand_id,params) => {
        return axios.request({
            url: 'fch/config/'+brand_id,
            method: 'get',
            params
        })
    };

// 修改配置
export const bashUpdate = (brand_id,data) => {
    return axios.request({
        url: '/code_rule/bash/update/'+brand_id,
        method: 'post',
        data
    })
};

export const bashUpdatepro = (brand_id,data) => {
        return axios.request({
            url: 'fch/config/'+brand_id,
            method: 'post',
            data
        })
    };

// 上传码包
export const cover_code = (data) => {
    return axios.request({
        url: '/code/cover_code',
        method: 'post',
        data
    })
};
// 修改备注
export const setCodeInfo = (data) => {
    return axios.request({
        url: '/code/setInfo',
        method: 'post',
        data
    })
};
// 码信息查询
export const codeSearch = (data)=>{
    return axios.request({
      url: 'code/get_code_info',
      method: 'post',
      data
    })
  };